import { selectedGeos } from '@utils/GeoUtils'
import { renderForGeoLocation } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, iamsaMcgExperienceMapper } from "./commonWidgetsIamsa"
import { getLineDetailedRadar } from '../widgetsLibrary/widgetsLine'
import { sortBy } from 'lodash'

const getIamsaRankingNormalWidgets = (filters: FiltersUI, filtersData: Record<string, any>) => {
  return [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      key: 'best-offices',
      title: 'Mejores oficinas',
      position: [0, 9],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SATISFACCION_GENERAL'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      key: 'worst-offices',
      title: 'Oficinas a mejorar',
      position: [1, 9],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SATISFACCION_GENERAL',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBestAndTopLocation',
      key: 'best-zones',
      title: 'Mejores divisiones',
      position: [2, 9],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SATISFACCION_GENERAL'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorstAndTopLocation',
      key: 'worst-zones',
      title: 'Divisiones a mejorar',
      position: [3, 9],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
      }, 
      indicator: 'SATISFACCION_GENERAL',
    }),
  ]
}

const getIamsaRankingLeafWidgets = (filters: FiltersUI, filtersData: Record<string, any>) => {
  return [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingPositionHighestScope',
      key: 'ranking-position-highest-scope',
      title: 'Posición entre oficinas de la misma división',
      position: [0, 9],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SATISFACCION_GENERAL'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingPositionLowestScope',
      key: 'ranking-position-lowest-scope',
      title: 'Posición entre todas las oficinas',
      position: [2, 9],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SATISFACCION_GENERAL',
    })
  ]
}

const getRankingIamsaWidgets = (filters: FiltersUI, filtersData: Record<string, any>) => {
  const geos = selectedGeos(filtersData.geographicDistributions, filters.geographicDistributions)

  if (geos !== undefined
    && geos.length === 1
    && geos[0] !== undefined
    && geos[0].children !== undefined
    && geos[0]?.children?.length === 0) {
    return getIamsaRankingLeafWidgets(filters, filtersData)
  } else {
    return getIamsaRankingNormalWidgets(filters, filtersData)
  }
}

const getIamsaWidgets = (filters: FiltersUI, filtersData: Record<string, any>) => [
  getLineDetailedRadar({
    mainIndicator: 'NPS',
    radialIndicators: [
      'NPS',
      'SATISFACCION_GENERAL',
      'AUTOBUS_LIMPIEZA',
      'VIAJE', // Viaje
      'AUTOBUS_COMODIDAD'
    ],
    position: [0, 0],
    size: [4, 4],
    title: 'NPS',
    extraConfigs: {
      backgroundColor: 'transparent',
      theme: 'dark',
      showRadialChart: true,
      decimals: 2
    },
    radialType: 'byIndicator',
    groupByLevel: 2,
    radialGrouped: 'logicalLocation',
    indicatorsNames: {
      'NPS': 'NPS',
      'SATISFACCION_GENERAL': 'Satisfacción',
      'AUTOBUS_LIMPIEZA': 'Limpieza',
      'VIAJE': 'Conductor', // Viaje
      'AUTOBUS_COMODIDAD': 'Comodidad'
    },
    extras: {
      periods: 0
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'gaugeGeneric',
    title: 'Satisfacción general',
    position: [0, 4],
    size: [1, 2],
    indicator: 'SATISFACCION_GENERAL',
    extraConfigs: {
      maxValue: 5,
      decimals: 2,
    },
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'gaugeGeneric',
    title: 'NPS General',
    position: [1, 4],
    size: [1, 2],
    indicator: 'NPS',
    extraConfigs: {
      maxValue: 100,
      decimals: 2,
      labelFontSize: 50,
    },
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'gaugeGeneric',
    title: 'Experiencia de marca',
    position: [2, 4],
    size: [1, 2],
    indicator: 'EXPERIENCIA_MARCA',
    extraConfigs: {
      maxValue: 5,
      decimals: 2,
    },
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'gaugeGeneric',
    title: 'Experiencia de Momentos Críticos',
    position: [3, 4],
    size: [1, 2],
    indicator: 'EXPERIENCIA_MOMENTO_CRITICO',
    extraConfigs: {
      maxValue: 5,
      decimals: 2,
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'barByQuestion',
    key: 'mcg-experience',
    title: 'Experiencia por cada momento crítico',
    position: [0, 6],
    size: [4, 2],
    indicator: 'EXPERIENCIA_MOMENTO_CRITICO',
    mapper: iamsaMcgExperienceMapper,
    extras: {
      featureExclude: ['groupByTopLocation']
    },
    extraIndicatorConfigs: {
      sort: { sortBy: 'value', value: 'desc' },
    },
    extraConfigs: {
      colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354'],
      showValues: true,
      percentageScale: false,
      maxValue: 5,
      wrapTexts: true,
    },
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'rankingByQuestionBest',
    title: 'Felicidades! Lo que mejor hacemos',
    indicator: 'EXPERIENCIA_MOMENTO_CRITICO',
    position: [0, 8],
    size: [2, 1],
    extraConfigs: {
      limit: 1,
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'rankingByQuestionWorst',
    title: 'Nuestro Reto. Lo que vamos a mejorar',
    indicator: 'EXPERIENCIA_MOMENTO_CRITICO',
    position: [2, 8],
    size: [2, 1],
    extraConfigs: {
      limit: 1,
    }
  }),
  ...getRankingIamsaWidgets(filters, filtersData),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    position: [0, 11],
    size: [4, 5],
    indicator: 'SENTIMENT',
    extraConfigs: {
      yAxisLabelConfig: {
        interval: 0
      },
      defaultHeight: 500
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'diagramaIshikawaByLogical',
    title: 'Puntos de Dolor',
    position: [0, 16],
    size: [4, 3],
    indicator: 'SENTIMENT'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCountWithLinks',
    title: 'Nube de Palabras / Frecuencia en comentarios',
    position: [0, 19],
    size: [2, 2],
    mapper: (data: any[], extra: any) => {
      console.log('nube de palabras mapper')
      console.log(data)
      return data
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'mapByGeos',
    title: 'Mapa puntos de ventas',
    position: [2, 19],
    size: [2, 2],
    indicator: 'NPS',
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'lastComments',
    indicator: 'COMMENTS',
    position: [0, 21],
    size: [4, 2],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'barByLogical',
    title: 'Tipo de servicio por momento crítico',
    indicator: 'EXPERIENCIA_MARCA',
    key: 'stmc-experience',
    position: [0, 23],
    size: [4, 2],
    extras: {
      featureExclude: ['groupByTopLocation'],
    },
    extraIndicatorConfigs: {
      sort: { sortBy: 'value', value: 'desc' },
    },
    extraConfigs: {
      colors: ['#ccc9c8', '#b2afae', '#868484', '#5d5c5c', '#0a0a0a'],
      maxValue: 5,
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'barByLogical',
    title: 'Experiencia General por Marca',
    indicator: 'EXPERIENCIA_MARCA',
    position: [0, 25],
    size: [4, 2],
    extras: {
      groupByLevel: 2,
    },
    extraIndicatorConfigs: {
      sort: { sortBy: 'value', value: 'desc' },
    },
    extraConfigs: {
      colors: '#7693a1',
      maxValue: 5,
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'barByQuestion',
    title: 'Experiencia de Marca',
    key: 'xgb-experience',
    indicator: 'EXPERIENCIA_MARCA',
    position: [0, 27],
    size: [4, 2],
    extraIndicatorConfigs: {
      sort: { sortBy: 'value', value: 'desc' },
    },
    extraConfigs: {
      colors: ['#9b633f', '#342115', '#c86120', '#a75019'],
      maxValue: 5,
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'barByGeo',
    key: 'xgmcd-experience',
    title: 'Experiencia de momentos críticos por división',
    indicator: 'SATISFACCION_GENERAL',
    position: [0, 29],
    size: [4, 2],
    extraConfigs: {
      colors: '#c0b7f4',
      rotateXAxisLegend: true,
      wrapTexts: true,
      labelFontSize: 10
    },
    extraIndicatorConfigs: {
      sort: { sortBy: 'value', value: 'desc' },
    },
    extras: {
      groupByLevel: 2,
    }
  }),
  /*getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'xgmcdExperience',
    position: [0, 27],
    size: [4, 2],
  }),*/
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'barByChannel',
    title: 'Experiencia de momentos críticos por canal',
    indicator: 'SATISFACCION_GENERAL',
    position: [0, 31],
    size: [4, 2],
    extraConfigs: {
      colors: ['#c7f3b0', '#b8dba5', '#96bd81', '#6e8e5d'],
      maxValue: 5,
    },
    extraIndicatorConfigs: {
      sort: { sortBy: 'value', value: 'desc' },
    }
  }),
]

export default {
  title: 'Marketing Dashboard',
  description: 'Dashboard de Director de Marketing',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI, filtersData: Record<string, any>) => getIamsaWidgets(filters, filtersData),
} as DasboardConfig
