import React, { useState } from 'react'
import { Button, Badge, Drawer, Row, Col, TreeSelect, Typography, Select } from 'antd'
import _ from 'lodash'
import dayjs from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'
import useActions from '@hooks/useActions'
import modules from '@modules'
import { treeNormalizer } from '@utils/index'
import { RangeValue } from 'rc-picker/lib/interface'
import useAccount from '@hooks/useAccount'

import './EpsilonFilterSidebar.less'
import useClientConfig from 'config/clients/useClientConfig'
import useClientTranslation from 'config/clients/useClientTranslation'
import usePrevious from '@hooks/usePrevious'

type PropsType = {
  filtersData: Record<string, any>
  filters: FiltersUI
}

const DatePicker = generatePicker<dayjs.Dayjs>(dayjsGenerateConfig)

const EpsilonFilterSidebar = ({ filters, filtersData, }: PropsType): JSX.Element => {
  const { userProfiles } = useAccount()

  const {
    includesFilterGeo,
    includesFilterCriticalMoment,
    includesFilterDate,
    includesFilterLogical,
    includesFilterCluster,
    includesFilterDynamic,
    getFilterCriticalMoments,
    getFilterCriticalMomentDefault,
    supportsInternationalization,
    getCriticalMomentFilterType,
    getCriticalMomentsRootId
  } = useClientConfig()

  const { translate } = useClientTranslation()

  const [drawerVisible, setDrawerVisible,] = useState<boolean>(false)
  const [localFilters, setLocalFilters,] = useState<FiltersUI>(filters)
  const [dateRangeOpen, setDateRangeOpen,] = useState<boolean>(false)
  const [dynamicFilters, setDynamicFilters,] = useState<any[]>([])
  const [responseValues, setResponseValues,] = useState<ResponseValueFiltersUI[]>(filters.responseValues)
  const dispatchers = useActions(modules.actions.appData, [])

  //console.log('Rendering filters side bar!!!!!!!!!!!!!!!!')
  //console.log(filtersData)
  //console.log(filters)
  //console.log(responseValues)

  const prevCriticalMoment = usePrevious(filters?.criticalMoments?.[0])

  /*React.useEffect(() => {
    console.log('cambio CM y filtros dinamicos!!!!')
    const selectedCm = filters.criticalMoments !== undefined
      && filters.criticalMoments !== null
      && filters.criticalMoments[0] !== undefined
      ? filters.criticalMoments[0]
      : undefined

    console.log(prevCriticalMoment)
    console.log(selectedCm)

    if (includesFilterDynamic()
      && selectedCm !== undefined
      && getCriticalMomentFilterType() === 'select') {
      calculateDynamicFilters(filters.criticalMoments[0])
    }

  }, [filters.criticalMoments,])*/

  React.useEffect(() => {
    setLocalFilters(filters)
  }, [filters,])

  const isSingleTree = (locations: any) => {
    return locations !== undefined
      && locations.length === 1
      && locations[0].children !== undefined
      && locations[0].children.length === 0
  }

  const _handleDateFilters = (dates: RangeValue<dayjs.Dayjs>) => {
    console.log('Cambiando fechas!!!')
    console.log(localFilters)
    if (!dates || !dates[0] || !dates[1]) {
      return
    }

    const isoDates = dates?.map((date) => date?.format('YYYY-MM-DD'))

    setLocalFilters({
      ...localFilters,
      fromDate: isoDates[0] ? isoDates[0] : localFilters.fromDate,
      toDate: isoDates[1] ? isoDates[1] : localFilters.toDate,
    })
  }

  const setFixedDatePeriod = (days: number) => {
    //console.log('Cambiando fechas fijas')

    setLocalFilters({
      ...localFilters,
      fromDate: dayjs().subtract(days, 'days').format('YYYY-MM-DD'),
      toDate: dayjs().format('YYYY-MM-DD'),
    })

    _handleDateFilters([
      dayjs(dayjs().subtract(days, 'days'), 'YYYY-MM-DD'),
      dayjs(dayjs(), 'YYYY-MM-DD'),
    ])
  }

  const _onFilterChange = (
    key: string,
    value: string | string[] | number | number[]
  ) => {
    console.log('_onFilterChange!!!!!')
    console.log(localFilters)
    console.log(responseValues)
    setLocalFilters({
      ...localFilters,
      [key]: value,
    })
  }

  const _onFilterFinish = () => {
    // Este método no tiene sentido porque tenemos el botón apply
  }

  const handleApplyFilters = () => {
    //console.log('apply filters!!!!!')
    //console.log(localFilters)
    //console.log(responseValues)
    const locals = {
      ...localFilters,
      responseValues: responseValues !== undefined
        && Object.keys(responseValues).length > 0
        ? responseValues : undefined
    }
    dispatchers.setFilters({
      ...filters,
      ...locals,
    })
    setDrawerVisible(false)
  }

  const handleClose = () => {
    setDrawerVisible(false)
  }

  const calculateDynamicFilters = (cmId: number) => {
    //console.log('calculate Dynamic Filters!!!!!!!!!!!!!')
    if (includesFilterDynamic()) {
      if (cmId !== undefined
        && getCriticalMomentsRootId() !== cmId
        && getCriticalMomentFilterType() === 'select') {
        const selectedCms = criticalMoments !== undefined
          ? criticalMoments.find((cm: any) => cm.id === cmId)
          : undefined

        const dfs = selectedCms !== undefined
          && selectedCms.configurationUi !== undefined
          && selectedCms.configurationUi !== null
          && selectedCms.configurationUi.metadataFilters !== undefined
          && selectedCms.configurationUi.metadataFilters !== null
          ? selectedCms.configurationUi.metadataFilters
          : []

        const dynamicFilters = dfs.filter((df: any) => df.options !== undefined
          && df.options !== undefined
          && df.options !== null
          && df.options.length > 0)

        setDynamicFilters(dynamicFilters)
      } else {
        setDynamicFilters([])
      }

      //console.log('vaciando response values!!!!!!!!!!!!!')
      setResponseValues([])
    }
  }

  const _onDynamicFilterChange = (props: { key: string, value: any }) => {
    const { key, value, } = props

    console.log('cambiando filtro dinamico!!!!!!!!!!!!!!')

    const newResponseValues = { ...responseValues, }

    if (value === undefined || value === null || _.isEmpty(value)) {
      delete newResponseValues[key]
    } else {
      newResponseValues[key] = value
    }
    console.log(newResponseValues)
    setResponseValues(newResponseValues)
  }

  const criticalMoments = getFilterCriticalMoments(filtersData, userProfiles)
  //console.log('Filter critical moments')
  //console.log(responseValues)
  //console.log(localFilters)
  //console.log(filters)



  return (
    <div className='filters-notification'>
      <Badge count={0} offset={[2, 5,]} className='alert-count-badge'>
        <div
          onClick={() => setDrawerVisible(true)}
          className='button-filter-open-drawer-style'
        >
          {translate('general.filters')}
        </div>
      </Badge>

      <Drawer
        className='filters-drawer'
        title={translate('general.filters')}
        width={290}
        closable
        onClose={() => handleClose()}
        visible={drawerVisible}
      >
        {/* <div className='title-filters-container'>
          <Typography className='title-filters-style'>{translate('general.filters')}</Typography>
        </div> */}

        { /* Distribución geografica */
          includesFilterGeo()
          && !isSingleTree(filtersData.geographicDistributions)
          && <div className='tree-select-container'>
            <span className='label'>{translate('titles.region')}</span>
            <TreeSelect
              treeData={treeNormalizer(filtersData.geographicDistributions)}
              treeCheckable={true}
              onChange={(value: any, node: any, extra: any) => {
                _onFilterChange('geographicDistributions', value)
              }}
              onBlur={_onFilterFinish}
              showArrow={true}
              value={
                localFilters.geographicDistributions
                  ? localFilters.geographicDistributions
                  : undefined
              }
              multiple={true}
              showCheckedStrategy={TreeSelect.SHOW_PARENT}
              maxTagCount={1}
              maxTagPlaceholder={(omittedValues: any) => `+${omittedValues.length}`}
              size={'middle'}
              treeNodeFilterProp={'label'}
              dropdownMatchSelectWidth={false}
              treeDefaultExpandedKeys={[filtersData.geographicDistributions &&
                filtersData.geographicDistributions[0] ? filtersData.geographicDistributions[0].id
                : undefined,]}
              style={{
                width: '100%',
              }}
            />
          </div>
        }

        { /* Distribución Lógica */
          includesFilterLogical()
          && !isSingleTree(filtersData.logicalDistributions)
          && <div className='tree-select-container'>
            <span className='label'>{translate('titles.logical')}</span>
            <TreeSelect
              treeData={treeNormalizer(filtersData.logicalDistributions)}
              treeCheckable={true}
              onChange={(value: any, node: any, extra: any) => {
                _onFilterChange('logicalDistributions', value)
              }}
              onBlur={_onFilterFinish}
              showArrow={true}
              value={
                localFilters.logicalDistributions
                  ? localFilters.logicalDistributions
                  : undefined
              }
              multiple={true}
              showCheckedStrategy={TreeSelect.SHOW_PARENT}
              maxTagCount={1}
              maxTagPlaceholder={(omittedValues: any) => `+${omittedValues.length}`}
              size={'middle'}
              treeNodeFilterProp={'label'}
              dropdownMatchSelectWidth={false}
              treeDefaultExpandedKeys={[filtersData?.logicalDistributions[0]?.id,]}
              style={{
                width: '100%',
                marginLeft: 0,
              }}
            />

          </div>
        }

        { /* Momento crítico */
          includesFilterCriticalMoment()
            && !isSingleTree(filtersData.criticalMoments)
            ? (
              getCriticalMomentFilterType() === 'select'
                ? <div className='tree-select-container'>
                  <span className='label'>{translate('titles.Critical_Moments')}</span>
                  <Select
                    style={{ width: '100%', }}
                    size={'middle'}
                    //defaultValue={getFilterCriticalMomentDefault(filtersData, userProfiles)}
                    value={localFilters.criticalMoments
                      && localFilters.criticalMoments[0]
                      ? localFilters.criticalMoments[0]
                      : getFilterCriticalMomentDefault(filtersData, userProfiles)}
                    onChange={(value: number) => {
                      _onFilterChange('criticalMoments', [value])
                      calculateDynamicFilters(value)
                    }}
                    showSearch={true}
                    optionFilterProp={'title'}
                  >
                    {criticalMoments !== undefined
                      && criticalMoments.map((criticalMoment: any) => (
                        <Select.Option
                          title={criticalMoment.description}
                          key={criticalMoment?.id}
                          value={criticalMoment?.id}
                        >
                          {
                            supportsInternationalization()
                              && criticalMoment.id
                              && translate('critical_moments.byId.' + criticalMoment.id)
                              ? translate('critical_moments.byId.' + criticalMoment.id)
                              : criticalMoment.description
                          }
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                : <div className='tree-select-container'>
                  <span className='label'>{translate('titles.Critical_Moments')}</span>
                  <TreeSelect
                    treeData={treeNormalizer(filtersData.criticalMoments)}
                    treeCheckable={true}
                    onChange={(value: any, node: any, extra: any) => {
                      _onFilterChange('criticalMoments', value)
                    }}
                    onBlur={_onFilterFinish}
                    showArrow={true}
                    value={
                      localFilters.criticalMoments
                        ? localFilters.criticalMoments
                        : undefined
                    }
                    multiple={true}
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                    maxTagCount={1}
                    maxTagPlaceholder={(omittedValues: any) => `+${omittedValues.length}`}
                    size={'middle'}
                    treeNodeFilterProp={'label'}
                    dropdownMatchSelectWidth={false}
                    treeDefaultExpandedKeys={[filtersData.criticalMoments[0].id,]}
                    style={{
                      width: '100%',
                      marginLeft: 0,
                    }}
                  />
                </div>
            ) : undefined
        }

        { /* Clusters */
          includesFilterCluster()
          && <div className='filter-box' style={{ width: 150, maxWidth: 150, }}>
            <span className='label'>{translate('titles.cluster')}</span>
            <Select
              style={{ width: '100%', }}
              size={'middle'}
              defaultValue={filters.cluster}
              onChange={(value) => _onFilterChange('cluster', value)}
              showSearch={true}
              optionFilterProp={'title'}
            >
              <Select.Option key={-1} value={-1}>
                --
              </Select.Option>
              {filtersData.clusters.map((cluster: any) => (
                <Select.Option
                  title={cluster.name}
                  key={cluster?.id}
                  value={cluster?.id}
                >
                  {cluster.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        }

        { /* Dynamic Filters */
          includesFilterDynamic()
            && dynamicFilters.length > 0
            ? <div className='dynamic-filters'>
              {
                dynamicFilters.map((df: any) => {
                  //console.log('Rendering dynamic filters')
                  return <div className='dynamic-filter-container'>
                    <span className='label'>{df.label}</span>
                    <Select
                      key={df.questionId}
                      mode="multiple"
                      style={{ width: '100%', }}
                      size={'middle'}
                      onChange={(value: number | string | string[] | number[] | undefined) => {
                        _onDynamicFilterChange({ key: df.questionId, value: value })
                      }}
                      showSearch={true}
                      optionFilterProp={'title'}
                    >
                      <Select.Option key={undefined} value={''}>
                        {' '}
                      </Select.Option>
                      {df.options.map((option: any) => (
                        <Select.Option
                          title={option}
                          key={df.questionId + '-' + option}
                          value={option}
                        >
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                })
              }
            </div>
            : undefined
        }

        <div className='container-selected-range-dates'>
          <Typography className='title-selected-range-dates'>{translate('titles.filterTo')}</Typography>
          <div className='container-range-dates'>
            <div onClick={() => setFixedDatePeriod(30)} className='button-style-range-date'>30 {translate('titles.days')}</div>
            <div onClick={() => setFixedDatePeriod(60)} className='button-style-range-date'>60 {translate('titles.days')}</div>
            <div onClick={() => setFixedDatePeriod(90)} className='button-style-range-date' style={{ marginRight: 0 }}>90 {translate('titles.days')}</div>
          </div>
        </div>
        <div className='container-title-date-picker'>
          <Typography className='title-style-range-filter-date-picker' style={{ marginRight: 90 }}>{translate('titles.fromDate')}</Typography>
          <Typography className='title-style-range-filter-date-picker'>{translate('titles.toDate')}</Typography>
        </div>
        <div className='container-date-picker-style'>
          {includesFilterDate()
            && <DatePicker.RangePicker
              /*defaultValue={[
                dayjs(filters.fromDate, 'YYYY-MM-DD'),
                dayjs(filters.toDate, 'YYYY-MM-DD'),
              ]}*/
              value={[
                dayjs(localFilters.fromDate, 'YYYY-MM-DD'),
                dayjs(localFilters.toDate, 'YYYY-MM-DD'),
              ]}
              allowClear={false}
              style={{ width: "100%", boxShadow: "none" }}
              size='large'
              format='DD/MM/YYYY'
              onChange={_handleDateFilters}
              onOpenChange={setDateRangeOpen}
              className='lmt-filter-bottom-bar-date-picker'
              suffixIcon={null}
            />}
        </div>

        <div className='container-apply-button-styled'>
          <Button type='primary' size='large' block onClick={handleApplyFilters}>{translate('general.apply')}</Button>
        </div>
      </Drawer>
    </div>
  )
}

export default EpsilonFilterSidebar
