import { COLORS_CONFIG, COLORS_CONFIG_BAR } from '@components/widgets/apacheEchart/Commons'
import { answeredMapper, invalidMapper, isGeoLocationLeaf } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import {
  commonWidgets,
} from './commonWidgetsMitsu'
import { getLineDetailedRadar } from '../widgetsLibrary/widgetsLine'

const getAllWidgets = (filters: FiltersUI, filtersData: Record<string, any>) => {
  console.log('get all widgets venta')
  console.log(filtersData)
  const isGeoLeaf = filtersData !== undefined
    ? isGeoLocationLeaf(filters, filtersData)
    : false

  return [
    getLineDetailedRadar({
      mainIndicator: 'VENTA_SATISFACCION',
      radialIndicators: [
        'VENTA_SATISFACCION',
        'VENTA_NPS',
        'VENTA_FINANCIAMIENTO',
        'VENTA_ENTREGA',
        'VENTA_EJECUTIVO'
      ],
      useNormalizedRadial: false,
      radialType: 'byIndicator',
      position: [0, 0],
      size: [4, 3],
      title: 'Satisfacción General',
      groupByLevel: 2,
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
        showRadialChart: true,
        initialVisibleChart: 1,
        /* maxValue: 10, */
      },
      criticalMomentInclude: [1],
      indicatorsNames: {
        'VENTA_SATISFACCION': 'Satisfacción',
        'VENTA_FINANCIAMIENTO': 'Financiamiento',
        'VENTA_EJECUTIVO': 'Venta Consultiva',
        'VENTA_NPS': 'NPS',
        'VENTA_ENTREGA': 'Entrega',
      },
    }),
    //1
    {
      title: 'Encuestas',
      type: 'label',
      position: [0, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    {
      title: 'Alertas',
      type: 'label',
      position: [2, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      position: [0, 4],
      size: [1, 2],
      indicator: 'survey-response-counters',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      position: [1, 4],
      size: [1, 2],
      indicator: 'survey-response-counters',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByMonth',
      position: [2, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByWeek',
      position: [3, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsVenta',
      position: [0, 6],
      size: [1, 2],
      indicator: 'VENTA_NPS',
      criticalMomentId: 1,
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snVenta',
      position: [1, 6],
      size: [1, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
      extraConfigs: {
        maxValue: 10
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosVenta',
      position: [2, 6],
      size: [1, 2],
      mapper: invalidMapper,
      url: 'survey-responses/values',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasVenta',
      position: [3, 6],
      size: [1, 2],
      mapper: answeredMapper,
      url: 'survey-responses/values',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      position: [0, 8],
      size: [4, 2],
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      criticalMomentId: 1,
      extraConfigs: {
        maxValue: 5
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasVenta',
      position: [0, 10],
      size: [2, 2],
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasVenta',
      position: [2, 10],
      size: [2, 2],
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: 1
    }),
    //3
    {
      title: 'Estaciones',
      type: 'label',
      position: [0, 12],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa de oficinas',
      position: [0, 13],
      size: [2, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentInclude: [1, 11703],
      extraConfigs: {
        bestLocationValueFrom: 9,
        worstLocationValueTo: 9
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsVenta',
      position: [2, 13],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
        symbol: ''
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentInclude: [1, 11703],
      render: (filters) => {
        return !isGeoLocationLeaf(filters, filtersData)
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsVenta',
      position: [3, 13],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
        symbol: ''
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentInclude: [1, 11703],
      render: (filters) => {
        return !isGeoLocationLeaf(filters, filtersData)
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingPositionHighestScope',
      title: 'widgets.rankingPositionHighestScope',
      position: [2, 13],
      size: [2, 1],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
        symbol: ''
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentInclude: [1, 11703],
      render: (filters) => {
        return isGeoLocationLeaf(filters, filtersData)
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingPositionLowestScope',
      title: 'widgets.rankingPositionLowestScope',
      position: [2, 14],
      size: [2, 1],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
        symbol: ''
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentInclude: [1, 11703],
      render: (filters) => {
        return isGeoLocationLeaf(filters, filtersData)
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsVenta',
      position: [0, 15],
      size: [2, 1],
      indicator: 'sentiment',
      criticalMomentId: 1,
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsVenta',
      position: [0, 16],
      size: [2, 1],
      indicator: 'sentiment',
      criticalMomentId: 1,
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinksSinDefaults',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [2, 15],
      size: [2, 2],
      indicator: 'WORD_COUNT_WITH_LINKS',
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'widgets.sentimentAnalysis',
      position: [0, 17],
      size: [4, 3],
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      title: 'Diagrama Ishikawa',
      position: [0, 20],
      size: [4, 3],
      indicator: 'sentiment',
      criticalMomentId: 1,
    }),
    {
      title: 'widgets.driveTestReasonsVenta',
      // [Column, Row]
      position: [0, 23],
      size: [2, 2],
      config: {
        id: 'venta-no-prueba',
        type: 'pie',
        fillMode: '',
        colors: ['#015c52', '#33b9ce', '#f38625'],
        showValues: true,
        //percentageScale: false,
        mapper: (data: any) => {
          return data.map((item: any) => ({
            id: item['group'] == null ? 'Indefinido' : item['group'],
            label: item['group'] == null ? 'Indefinido' : item['group'],
            value: item['value'],
          }))
        },
        indicators: [
          {
            indicator: 'VENTA_SIN_PRUEBA_MANEJO_MOTIVO',
            //grouped: 'responseValue',
            keyExtract: ['group', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              criticalMomentId: 1,
              groupNameFormat: 'description',
            },
          },
        ],
      },
    },
    {
      title: 'widgets.financingReasonsVenta',
      // [Column, Row]
      position: [2, 23],
      size: [2, 2],
      config: {
        id: 'venta-no-financiacion',
        type: 'pie',
        fillMode: '',
        colors: ['#015c52', '#33b9ce', '#f38625'],
        showValues: true,
        //percentageScale: false,
        mapper: (data: any) => {
          return data.map((item: any) => ({
            id: item['group'] == null ? 'Indefinido' : item['group'],
            label: item['group'] == null ? 'Indefinido' : item['group'],
            value: item['value'],
          }))
        },
        indicators: [
          {
            indicator: 'VENTA_SIN_FINANCIAMIENTO_MOTIVO',
            //grouped: 'geoLocation',
            keyExtract: ['group', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.otherIndicators',
      type: 'label',
      position: [0, 25],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.instalationsVenta',
      position: [0, 26],
      size: [1, 2],
      indicator: 'VENTA_INSTALACIONES',
      criticalMomentId: 1,
      extraConfigs: {
        maxValue: 5,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.parkingVenta',
      position: [1, 26],
      size: [1, 2],
      indicator: 'VENTA_ESTACIONAMIENTO',
      criticalMomentId: 1,
      extraConfigs: {
        maxValue: 5,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.upsellingVenta',
      position: [2, 26],
      size: [1, 2],
      indicator: 'VENTA_ACCESORIOS_ADICIONALES',
      criticalMomentId: 1,
      extraConfigs: {
        maxValue: 5,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.carStatusVenta',
      position: [3, 26],
      size: [1, 2],
      indicator: 'VENTA_ESTADO_VEHICULO',
      criticalMomentId: 1,
      extraConfigs: {
        maxValue: 5,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.warrantyExplanation',
      position: [0, 28],
      size: [1, 2],
      indicator: 'VENTA_GARANTIA',
      criticalMomentId: 1,
      extraConfigs: {
        maxValue: 5,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: 'widgets.zonalSatisfaction',
      key: 'venta-satisfaction-zona',
      position: [1, 28],
      size: [3, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
      extraConfigs: {
        colors: COLORS_CONFIG_BAR,
        itemsWidth: 200,
        percentageScale: false,
        decimals: 0,
      },
      render: (filters) => {
        return !isGeoLocationLeaf(filters, filtersData)
      },
      extras: {
        groupByLevel: 2
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: 'widgets.distribuidorSatisfaction',
      key: 'venta-satisfaccion-dist',
      position: [0, 30],
      size: [4, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
      extraConfigs: {
        colors: COLORS_CONFIG_BAR,
        itemsWidth: 200,
        percentageScale: false,
        decimals: 0,
        rotateXAxisLegend: true,
        wrapTexts: true,
        labelFontSize: 10,
      },
      extras: {
        groupByLevel: 3
      },
      render: (filters) => {
        return !isGeoLocationLeaf(filters, filtersData)
      },
      mapper: (data: any) => {
        return [...data].sort((a: any, b: any) => b.value - a.value).map((item: any) => ({
          ...item,
          label: item.label !== undefined
            ? item.label.replaceAll('MITSUBISHI ', '')
            : item.label
        }))
      },
    }),
  ]
}

export default {
  title: 'Nacional Venta Dashboard',
  description: 'Dashboard de Nacional Venta',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: getAllWidgets,
} as DasboardConfig
